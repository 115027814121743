require('axios');

$(document).ready(function () {
    axios.get('/interest-rate').then(res => {
        calculateMinimumRates(
            res.data.rates.analysis_rate,
            res.data.rates.insurance_rate,
            res.data.rates.base_rate
        );
    });

});

const calculateMinimumRates = (analysis_rate, insurance_rate, base_rate) => {
    $('.grid-prices').each((index, item) => {
        let price = item.getAttribute('data-price');

        updateMinimumRateHtml(item.getAttribute('data-id'), calculateCarMinimumRate(analysis_rate, insurance_rate, base_rate, price));
    });
}

const calculateCarMinimumRate = (analysis_rate, insurance_rate, base_rate, price) => {
    const monthsNo = 60;

    let insuranceSum = (parseFloat(insurance_rate) / 100) * (parseFloat(price) + parseFloat(analysis_rate)) * monthsNo;
    let totalValue = parseFloat(price) + parseFloat(analysis_rate) + insuranceSum;

    let up = totalValue * (parseFloat(base_rate) / 100 / 12);

    let base = 1 + parseFloat(base_rate) / 100 / 12;
    let exp = -1 * monthsNo;

    let down = 1 - Math.pow(base, exp);

    return (up / down).toFixed(2);
}

const updateMinimumRateHtml = function (id, rate) {
    $(`span[data-id="${id}"]`).text(rate);
}
